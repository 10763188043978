@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input {
  --tw-ring-shadow: 0 0 #000 !important;
}
